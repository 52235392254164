<template>
  <div class="big-box">
    <el-form label-position="left" :inline="true" class="demo-form-inline">
      <el-form-item label="视频标题">
        <el-input v-model="video_title" placeholder="请输入视频标题"></el-input>
      </el-form-item>
      <el-form-item label="类型：">
        <el-select v-model="video_category" placeholder="请选择">
          <el-option label="外观" value="outline"></el-option>
          <el-option label="减害" value="sub_harmful"></el-option>
          <el-option label="操作" value="operation"></el-option>
          <el-option label="全部" value=""></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="video_status" placeholder="请选择">
          <el-option label="生效" value="1"></el-option>
          <el-option label="待生效" value="0"></el-option>
          <el-option label="全部" value=""></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search('clear')">查询</el-button>
        <el-button @click="clearModuleCode">重置</el-button>
        <el-button type="primary" @click="addItem" v-if="$has('add')">新增</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-left: 50px">
      <el-button type="danger" @click="delData" v-if="$has('del')">删除</el-button>
      <el-button type="danger" @click="editStatus(0)" v-if="$has('change')">失效</el-button>
      <el-button type="success" @click="editStatus(1)" v-if="$has('change')">生效</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange"
          style="width: 90%; margin-left: 40px">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="视频标题" min-width="150">
          </el-table-column>

          <el-table-column prop="category" align="center" label="类型" min-width="80">
            <template slot-scope="scope">
              <span>{{scope.row.category == 'outline' ? '外观' : (scope.row.category == 'sub_harmful' ? '减害' :
              '操作')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="first_img_id" align="center" label="视频首图" min-width="150">
            <template slot-scope="scope">
              <img style="max-width: 100px;max-height: 100px" @click="checkImg(scope.row.first_img_id)"
                :src="scope.row.first_img_url" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="summary_img_id" align="center" min-width="150" label="知识点汇总图">
            <template slot-scope="scope">
              <img style="max-width: 100px;max-height: 100px" @click="checkImg(scope.row.summary_img_id)"
                :src="scope.row.summary_img_url" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="sort" align="center" min-width="80" label="排序">
          </el-table-column>
          <el-table-column prop="question_num" align="center" min-width="80" label="题库数">
          </el-table-column>
          <el-table-column prop="answer_round_num" align="center" min-width="120" label="答题轮数">
          </el-table-column>
          <el-table-column prop="answer_times" align="center" min-width="120" label="每轮答题数">
          </el-table-column>
          <el-table-column prop="status" align="center" min-width="100" label="状态">
            <template slot-scope="scope">
              <span :style="{color: scope.row.status == 1 ? '' : 'red'}">{{scope.row.status == 1 ? '生效' : '待生效'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updated_at" align="center" min-width="150" label="更新时间">
          </el-table-column>
          <el-table-column prop="date" align="center" min-width="150" label="操作" v-if="$has('edit')">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="editItem(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-left: 50px;margin-top: 10px" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
    </div>
    <el-dialog :visible.sync="dialogPvVisible" width="700px" :title="statusType == 1 ? '详情' : '新增'">
      <el-form label-width="150px" :inline="true" :disabled="isFormDisabled">
        <el-form-item label="视频地址:" required>
          <el-input v-model="video_id" placeholder="请输入视频地址"></el-input>
        </el-form-item>
        <el-form-item label="视频标题:" required>
          <el-input v-model="videoTitle" placeholder="请输入视频标题" maxlength="16"></el-input>
        </el-form-item>
        <el-form-item label="类型:" required>
          <el-select v-model="category" placeholder="请选择">
            <el-option label="外观" value="outline"></el-option>
            <el-option label="操作" value="operation"></el-option>
            <el-option label="减害" value="sub_harmful"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="视频首图:" required>
          <el-input v-model="first_img_id" placeholder="请输入视频首图"></el-input>
          <el-button style="margin-left: 10px" size="small" type="primary" @click="checkImg(first_img_id)">查看
          </el-button>
        </el-form-item>
        <el-form-item label="知识点汇总图:" required>
          <el-input v-model="summary_img_id" placeholder="请输入知识点汇总图"></el-input>
          <el-button style="margin-left: 10px" size="small" type="primary" @click="checkImg(summary_img_id)">查看
          </el-button>
        </el-form-item>
        <el-form-item label="排序:" required>
          <el-input type="number" v-model="sort" placeholder="请输入排序" oninput="if(value>99)value=99;if(value.length>3)value=value.slice(0,3);if(value<1)value=1"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="题库数据:" required>
          <!-- <span>{{fileList && fileList.length > 0 ? "" : file_name}}</span> -->
          <el-upload style="display: inline-block" class="upload-demo" action="/api/admin/heat_curve/del" accept=".xlsx"
            ref="upload" :limit="1" :file-list="fileList" :http-request="(file) => uploadFiles(file)">
            <!-- @click="upload(scope.row)" -->
            <el-button @click="clearFiles" type="primary" size="small">{{statusType == 1 ? '重新上传' : '选择文件'}}</el-button>
          </el-upload>
          <div>
            <el-button type="primary" size="small" @click="download()">{{statusType == 1 ? '下载' : '下载模板'}}</el-button>
          </div>
        </el-form-item>
        <br />
        <el-form-item label="答题轮数:" required>
          <el-input v-model="answer_round_num" placeholder="请输入答题轮数"></el-input>
        </el-form-item>
        <el-form-item label="每轮答题数:" required>
          <el-input v-model="answer_times" placeholder="请输入每轮答题数"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPvVisible = false">取消</el-button>
        <el-button type="primary" v-if="statusType !== 1" @click="submitadd">确认</el-button>
        <el-button type="primary" v-if="statusType == 1 && isFormDisabled" @click="toEditStatus">修改</el-button>
        <el-button type="primary" v-if="statusType == 1 && !isFormDisabled" @click="submitadd">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <CheckPhoto ref="CheckPhoto" :imgId='imgId' :imgUrl="imgUrl"></CheckPhoto>
  </div>
</template>
<script>
import { collegeSave, collegeUpdate, collegeDelete, getcollegelist } from "@/api/data.js";
import CheckPhoto from "@/components/CheckPhoto.vue";
export default {
  components: { CheckPhoto },
  data() {
    return {
      page: 1,
      statusType: '',
      data_id: '',
      video_id: '',
      imgId: '',
      videoTitle: '',
      category: '',
      first_img_id: '',
      summary_img_id: '',
      sort: '',

      answer_round_num: '',
      formData: '',
      // 表单是否禁止输入
      isFormDisabled: true,
      video_title: '',
      video_category: '',
      video_status: '',
      question_url: '',
      answer_times: '',
      imgUrl: '',
      fileList: [],
      file_name: '',
      total: 0,
      title: '',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      multipleSelection: [],
      dialogPvVisible: false,
      tableData: [{}],
      roles: ['admin', 'user'],
    };
  },
  created() {
  },
  watch: {
  },
  mounted() {
    this.search('clear')
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    upload() {
    },
    clearFiles() {
      this.$refs.upload.clearFiles()
    },
    // uploadExceed(files, fileList) {
    //   this.$refs.upload.clearFiles();//清除文件
    //   this.fileList = [files[files.length-1]]
    // },
    uploadFiles(file, item) {
      this.formData = file.file
    },
    download() {
      let link = ''
      link = this.statusType == 1 ? this.question_url : 'http://dev.api.salesclerk.wonzi.com/college.xlsx'

      var a = document.createElement("a");
      a.href = link;
      document.body.appendChild(a);
      a.click();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    editItem(item) {
      this.isFormDisabled = true
      this.statusType = 1
      this.data_id = item.id
      this.video_id = item.video_id
      this.videoTitle = item.title
      this.category = item.category
      this.first_img_id = item.first_img_id
      this.summary_img_id = item.summary_img_id
      this.sort = item.sort
      this.answer_round_num = item.answer_round_num
      this.answer_times = item.answer_times
      // this.file_name = item.file_name
      this.question_url = item.question_url
      // 文件名回显
      this.fileList = [{
        name: item.file_name,
      }]
      this.dialogPvVisible = true
    },
    addItem() {
      this.isFormDisabled = false
      this.fileList = []
      this.statusType = 0
      this.video_id = ''
      this.videoTitle = ''
      this.category = ''
      this.first_img_id = ''
      this.summary_img_id = ''
      this.sort = ''
      this.answer_round_num = ''
      this.answer_times = ''
      this.dialogPvVisible = true
    },
    search(type) {
      if (type == 'clear') {
        this.page = 1
      }
      getcollegelist(
        `?page=${this.page}&limit=10&title=${this.video_title}&category=${this.video_category}&status=${this.video_status}`
      ).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$message.error(res.message);
        }
      });
    },
    clearModuleCode() {
      this.video_title = ''
      this.video_category = ''
      this.video_status = ''
      this.page = 1
      this.search('clear')
    },
    checkImg(id) {
      this.imgId = id
      this.$refs.CheckPhoto.open();
    },
    submitadd() {
      // if () {
      //   this.$message.error('请输入快递公司及单号');
      //   return 
      // }
      const valid = this.dataValidation()
      if (valid) {
        let formData = new FormData();
        if (this.statusType === 1) {
          formData.append("id", this.data_id);
        }
        formData.append("file", this.formData);
        formData.append("video_id", this.video_id);
        formData.append("title", this.videoTitle);
        formData.append("category", this.category);
        formData.append("first_img_id", this.first_img_id);
        formData.append("summary_img_id", this.summary_img_id);
        formData.append("sort", this.sort);
        formData.append("answer_round_num", this.answer_round_num);
        formData.append("answer_times", this.answer_times);

        collegeSave(formData).then(({ data: res }) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.search('clear')
            this.dialogPvVisible = false
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    dataValidation() {
      if (!this.video_id) {
        this.$message.error('视频地址不能为空')
        return false
      }
      if (!this.videoTitle) {
        this.$message.error('视频标题不能为空')
        return false
      }
      if (!this.category) {
        this.$message.error('类型不能为空')
        return false
      }
      if (!this.first_img_id) {
        this.$message.error('视频首图不能为空')
        return false
      }
      if (!this.summary_img_id) {
        this.$message.error('知识点汇总图不能为空')
        return false
      }
      if (!this.sort) {
        this.$message.error('排序不能为空')
        return false
      }
      if (this.statusType !== 1 && !this.formData) {
        this.$message.error('题库数据不能为空')
        return false
      }
      if (!this.answer_round_num) {
        this.$message.error('答题轮数不能为空')
        return false
      }
      if (!this.answer_times) {
        this.$message.error('每轮答题数不能为空')
        return false
      }
      return true
    },
    toEditStatus() {
      this.isFormDisabled = false
    },
    editStatus(type) {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
      if (type === 1) {
        const isAllOff = this.multipleSelection.every(function (item, index, arr) {
          return item.status === 0;
        });
        if (!isAllOff) {
          this.$message.error("数据需均为待生效才可处理");
          return
        }
      }
      if (type === 0) {
        const isAllOn = this.multipleSelection.every(function (item, index, arr) {
          return item.status === 1;
        });
        if (!isAllOn) {
          this.$message.error("数据需均为生效才可处理");
          return
        }
      }
      this.$confirm(type == 1 ? '选中的数据将生效！' : '选中的数据将失效！', "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => { })
        .catch((action) => {
          if (action === "cancel") {
            collegeUpdate({
              status: type,
              ids: this.multipleSelection.map(item => {
                return item.id
              }),
            }).then(({ data: res }) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.search('clear')
              } else {
                this.$message.error(res.message);
              }
            });
          }
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delData() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
      const isAllOff = this.multipleSelection.every(function (item, index, arr) {
        return item.status === 0;
      });
      if (!isAllOff) {
        this.$message.error("数据均为待生效才可处理");
        return
      }
      this.$confirm("一旦删除数据，将不可恢复！", "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => { })
        .catch((action) => {
          if (action === "cancel") {
            collegeDelete({
              ids: this.multipleSelection.map(item => {
                return item.id
              }),
            }).then(({ data: res }) => {
              if (res.code === 200) {
                this.$message.success("删除成功");
                this.page = 1
                this.search('clear')
              } else {
                this.$message.error(res.message);
              }
            });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.text-content {
  display: inline-block;
  width: 260px;
  padding-left: 20px;
}

.demo-form-inline {
  margin-left: 50px;
}

.head-box {
  height: 40px;
  margin: 20px 0 10px 40px;
}

.add-image {
  text-align: center;
  color: #3ea8d6;
}

.demo-form-inline {
  margin-top: 22px;
  // padding-left: 50px;
}

.input-box {
  width: 120px;
  margin-right: 10px;
}

::v-deep .el-input {
  width: 260px;
}

::v-deep .el-upload-list__item-name {
  // display: none;
}

::v-deep .el-upload .el-button {}

.download_button {}
</style>
